import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";

import { PUBLIC_ANALYTICS_ENV } from "$env/static/public";

Sentry.init({
  enabled: PUBLIC_ANALYTICS_ENV === "production" || PUBLIC_ANALYTICS_ENV === "stage",
  dsn: "https://f64f71d572648e3b77f2ad2923b6194d@o4506633003859968.ingest.sentry.io/4506633020178432",
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [new Replay()]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
